<template >
  <div class="syj_PersonalBetdetails" v-if=" order_timestatus == 1">
    <div class="syj-commontitle syj_page-width">
      <title class="commom-title"><span>投注明细</span></title>
    </div>
    <ul class="time-record syj_page-width">
      <li class="listfrist">
        <span v-for="(realtitle, index) in realtitlelist" :key="index">
          {{ realtitle.name }}
        </span>
      </li>
      <li
        v-for="(realtimeorder, index) in realtimeorderlist"
        :key="index"
        :class="realtimeorder.eliminate_status ==  1 ? 'gray' : 'active'" 
      >
        <span>{{ realtimeorder.company }}</span>
        <span>{{ realtimeorder.parent_department }}</span>
        <span class="srcname"><img :src="realtimeorder.avatar_url"> {{ realtimeorder.name }}</span>
        <span>{{ realtimeorder.type }}</span>
        <span>{{ realtimeorder.award_number }}</span>
        <span>{{ realtimeorder.order_commitment }}</span>
      </li>
      <li class="last">
        <button v-if="!issOpen"  @click="isOpen  " >
          查看更多>
        </button>
        <button v-else>
          我是有底线的...
        </button>
      </li>
    </ul>
  </div>
</template>
  <script>
// import maiyuanRequest from "../../units/maiyaunRuquest.js";
 import { getActivity } from "../../units/activity.js";
import { memberbettingdetails } from "../../units/api.js";
export default {
  name: "realTimeorder",
  inject: ["reload"],

  data() {
    return { timeorder:null,
            currentPage: 1, //当前页数 ，默认为1
            pageSize:"", // 每页显示数量  
       issOpen: false, // 是否展开全部信息的标识 Boolean 默认false
      props: [],
      timetotal: "031456",
      realtitlelist: [
        { name: "公司" },
        { name: "部门" },
        { name: "姓名" },
        { name: "类别" },
        { name: "投注号码" },
        { name: "下单承诺" },
      ],
      realtimeorderlist: [ 
      ],
    };
  },
  props: {
    order_timestatus: {
       type: [Number, String], 
      default:""
    }, 
  },
  methods: {
    PersonalBetdetails() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
        let activtiyId = activityInfo.activityId || null;
        let activityRoundId = activityInfo.activityRoundId || null; 
        let params = {page: that.currentPage, limit: 10,activtiy_id:activtiyId, activity_round_id: activityRoundId };
      // maiyuanRequest({
      //   method: "get",
      //   url: "betting_details",  
      //   params: {page: that.currentPage, limit: 10,activtiy_id:activtiyId, activity_round_id: activityRoundId },
      // })
      memberbettingdetails(params ).then((res) => { 
        let response = res.data;  
        console.log('response.data',response.data.length); 
        if(response.data.length > 0){
        response.data.forEach((e)=>{
            that.realtimeorderlist.push(e)
          })
        that.currentPage ++
        }else{
        this.issOpen=!this.issOpen 
        }
      });
    },   
  
     
    clearrefresh() {
      this.reload();
      console.log(this.reload);
    },
    isOpen(){ 
        this.PersonalBetdetails()  
        // console.log(this.realtimeorderlist.length )
    } 
  },
  mounted(){     
    this.PersonalBetdetails()
    // this.$bus.$on("suiji",(val)=>{
    //         this.timeorder= val 
    //         console.log("投注明细获取实时订单数量",val)
    //         this.PersonalBetdetails(); 
    // })  
 
  }
};
</script>
  <style lang="less">
.syj_PersonalBetdetails { 
    padding-bottom: 80px;
  .syj-commontitle {
    display: flex;
    padding: 40px 27px;
    position: relative;
  }
  ul.time-record {
    li {
      font-weight: lighter;
      font-size: 15px;
      span {
        &.srcname{
    display: flex;
    justify-content: flex-start;
    align-items: center;padding-left: 34px;
    width: 13%; }
        img{width: 35px;height: 35px; 
    border-radius: 50%;
    margin-right: 10px;}
        width: calc(100% / 6);
      } &.gray{
    background: #363fb5;
    border-bottom: 1px solid #363fb5;}
      &:nth-child(2n-1) { &.gray{
    background: #363fb5;
    border-bottom: 1px solid #363fb5;}
        background: #363fb5;
      }
      &.listfrist { 
        color: #fff;
        background-image: linear-gradient(90deg, #dd0e19, #dd0e19, #dd0e19);
        height: 44px;border-image:linear-gradient(90deg, #dd0e19, #dd0e19, #dd0e19);
      }

      &.active {
        color: #fff;
      }
      height: 50px;
      border-bottom: 1px solid;
      color: #fff;
      border-image: linear-gradient(90deg, #363fb5, #363fb5, #363fb5) 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 0 32px;
      &.last {
        text-align: center;
        display: inline-block;
        margin: 1rem 0 0.5rem;
        border: none;
        button {
          cursor: pointer;
    height: 35px;
    color: #fff;
    background: linear-gradient(#363fb5 13%, #363fb5 76%);
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 3px;
    padding: 0 40px;
    box-shadow: 1px 3px 6px -1px #552d61;
        }
      }
    }

    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }
}
</style>