<template>
  <div class="syj_PersonalCenter">
    <div class="syj-PersonalCenter-content syj_page-width">
      <!-- logo 个人中心 -->
      <toplogoCustomer-info></toplogoCustomer-info>
      <div class="syj-logo-message">
        <div>
          <div class="user-datail">
            <span class="user-headerimg"><img :src="avatar_url"/> </span>
            <div class="right">
              <span class="name">{{ customer }}</span>
              <span class="detail-right">
                <span>{{ company }}</span>
                <span>{{ parent_department }}-{{ department }}</span>
              </span>
              <button class="exit-btn" @click="onExit">退出登录</button>
            </div>
          </div>
        </div>
        <!-- 客户留言弹幕  start -->
        <!-- <message-info :parentStyle="1"></message-info> -->
      </div>
      <!-- 竞猜记录 -->
      <Personalguessrecord-info :modifydetail="modifydetail"
                                :bets_lenght="betslenght"
      ></Personalguessrecord-info>
    </div>
    <!-- 投注明细 start -->
    <Personalbetdetails-info
        :order_timestatus="ordertimestatus"
    ></Personalbetdetails-info>
  </div>
</template>
<script>
// import message from "@/components/message";
import Personalbetdetails from "@/components/PersonalCenter/PersonalBetdetails";
import Personalguessrecord from "@/components/PersonalCenter/PersonalGuessrecord";
import Toplogocustomer from "@/components/toplogoCustomer";
import {memberInfo, currentActivity} from "../../units/api.js";
import {setToken} from "@/units/token";

export default {
  name: "PersonalCenter",
  data() {
    return {
      currentActivityInfo: null,
      timeEnd: "2023-3-30 9:50",
      customer: " ",
      avatar_url: "",
      parent_department: "",
      department: "",
      company: "",
      ordertimestatus: null,
      betslenght: null,
      modifydetail: null
    };
  },
  provide() {
    return {
      '$_parent': this
    }
  },
  components: {
    // "message-info": message,
    "Personalbetdetails-info": Personalbetdetails,
    "Personalguessrecord-info": Personalguessrecord,
    "toplogoCustomer-info": Toplogocustomer,
  },
  methods: {
    jennyordertime() {
      let that = this;
      currentActivity().then((res) => {
        let response = res.data.data;
        this.currentActivityInfo = response
        that.ordertimestatus = response.activity_round.member_betting_status;
        //  console.log('当前活动', this.ordertimestatus)
        that.betslenght = response.activity_round.bets_lenght;
        that.modifydetail = response.activity_round ? response.activity_round : '';
        console.log("sunyanjie122222222222222", that.ordertimestatus);
      });
    },
    PersonalCenterMain() {
      let that = this;
      memberInfo().then((res) => {
        let response = res.data;
        that.customer = response.data.name;
        that.avatar_url = response.data.avatar_url;
        that.company = response.data.company;
        that.parent_department = response.data.parent_department;
        that.department = response.data.department;
        console.log("个人中心", response.data.token);
      });
    },
    
    onExit() {
      setToken('')
      this.$router.push('/login')
    }
  },
  mounted() {
    this.PersonalCenterMain();
    this.jennyordertime();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

.syj_PersonalCenter {
  .user-datail {
    display: flex;
    align-items: center;
    padding: 26px 0 26px 26px;
    
    .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      
      span.name {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
      }
      
      span.detail-right {
        display: flex;
        font-size: 12px;
        flex-direction: column;
        text-align: left;
        padding-left: 15px;
      }
    }
    
    .user-headerimg {
      width: 54px;
      margin-right: 15px;
      height: 54px;
      background: #fff;
      display: block;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  
  .toplogoCustomer {
    .feihome {
      display: block;
    }
    
    .syj_enter-login-content {
      padding-bottom: 20px;
      position: relative;
      color: #fff;
    }
    
    position: relative;
  }
  
  .syj-logo-message {
    border-radius: 20px;
    background-image: -moz-linear-gradient(0deg, #dd0e19 0%, #dd0e19 100%);
    background-image: -webkit-linear-gradient(0deg, #dd0e19 0%, #dd0e19 100%);
    background-image: -ms-linear-gradient(0deg, #dd0e19 0%, #dd0e19 100%);
    background-image: linear-gradient(0deg, #dd0e19 0%, #dd0e19 100%);
    
    .marquee {
      .message-comment {
        top: -30%;
      }
      
      padding-top: 30px;
    }
  }
  
  color: #fff;
  background: #2a0337;
  min-height: 1080px;
  z-index: 1;
  
  ul {
    padding: 0;
    margin: 0;
  }
  
  .syj_page-width {
    max-width: 1200px;
    position: relative;
    margin: auto;
  }
}
</style>

<style scoped lang="less">
.syj_PersonalCenter {
  .user-datail {
    padding: 26px;
    
    .user-headerimg {
      flex-shrink: 0;
    }
    
    .right {
      flex: 1;
    }
  }
}

.exit-btn {
  width: 80px;
  height: 36px;
  border: none;
  margin-left: auto;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
}
</style>
