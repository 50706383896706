<template>
  <div class="syj_PersonalGuessrecord">
    <div class="syj_border-linear"></div>

    <title class="commom-title"><span>竞猜记录</span></title>
    <div class="pay_option">
        <button class="color_guess" @click="payment()">支付</button>
        <button v-if="1==2" class="color_guess" @click="unlock()">解锁</button>
    </div>
    <div class="title">
      <span v-for="recorditem in recorditems" :key="recorditem.key">
         {{ recorditem.name ? '[' + recorditem.name + ']' : ''}}
        <input type="checkbox" v-if="!recorditem.name"  v-model="isAllChecked" @change="toggleSelectAll"  />
        <span v-if="!recorditem.name"></span>
      </span>
    </div>
    <ul>
      <li v-for="(item, index) in newrecordlists" :key="index">
        <div class="recordlisttitle">
          <span>
            <input type="checkbox" v-if="item.pay_status != 1 && item.pay_status != 2"  v-model="selectedCheckboxes" :value="item.id"/>
          </span>
          <span>{{ item.order_sn }}</span>
          <span>{{ item.create_time }}</span>
          <span>{{ item.activity_title }}</span>
          <span>{{ item.is_lottery }}</span>
          <span v-if="item.pay_status!=1 && item.pay_status!=2">{{ item.pay_status_desc }}</span>
          <span  v-else   style="cursor: pointer;" v-on:click="openModal(item.image_url)">
            {{ item.pay_status_desc }}
            <font v-if="item.image_url!=''" style="color: #00c4ff; text-decoration: underline;font-size: 13px;">点击查看付款凭证</font>
          </span>
          <div class="right">
            <span v-if="item.pay_status==1 || item.pay_status==2"  class="gray">修改</span>
            <span  v-else @click="onOpenbet(item.id)" >修改</span>
            <span @click="onOpenbet()">再来一单</span>
            <span @click="DetailOpen(index)">查看详情</span>
            <span v-if="item.pay_status == -1" @click="Payermaintenant(item.id)">支付</span>
          </div>
        </div>
        <div v-if="bets_lenght == 6">
          <div class="betNum" v-if="item.isOpen">
            <div
                class="syj_betnumDetail"
                v-for="(info, index) in item.info"
                :key="index"
            >
              <div class="syj_betnumDetail-input">
                <div class="inputnum">
                  <span> {{ info.num1 }} </span>
                  <span> {{ info.num2 }} </span>
                  <span> {{ info.num3 }} </span>
                  <span> {{ info.num4 }} </span>
                  <span> {{ info.num5 }} </span>
                  <span> {{ info.num6 }} </span>
                  <span> {{ info.lucky_num }} </span>
                </div>
                <div class="prizegrade">
                  {{ info.level_prize }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else >
          <div class="betNum" v-if="item.isOpen">
            <div
                class="syj_betnumDetail"
                v-for="(info, index) in item.info"
                :key="index"
            >
              <div class="syj_betnumDetail-input">
                <div class="inputnum">
                  <span> {{ info.num1 }} </span>
                  <span> {{ info.num2 }} </span>
                  <span> {{ info.num3 }} </span>
                  <span> {{ info.num4 }} </span>
                  <span> {{ info.num5 }} </span>
                  <span> {{ info.lucky_num }} </span>
                </div>
                <div class="prizegrade">
                  {{ info.level_prize }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <PayQrCode-info   @IsCloseqrcodelist="IsCloseQrcode" v-if="onOpenQrcode" :parentQrcodeData="QrcodeData"></PayQrCode-info>
    <div v-if="bets_lenght == 6">
    <Betpopsix-info
      @IsClosebetlist="IsClosebet"
      @editData="editDataChild"
      :parentBetListInputline="BetListInputline"
      :orderId="orderId"
      :editFlag="editFlag"
      v-if="BetListInputlineFlag"
    ></Betpopsix-info> 
    </div>
    <div v-else>
      <Betpop-info
      @IsClosebetlist="IsClosebet"
      @editData="editDataChild"
      :parentBetListInputline="BetListInputline"
      :orderId="orderId"
      :editFlag="editFlag"
      v-if="BetListInputlineFlag"
    ></Betpop-info></div>
    <div v-if="showModal" class="modal" @click.self="closeModal">
      <span class="close" @click="closeModal">&times;</span>
      <img :src="imageUrl" alt="付款截图">
    </div>
  </div>
</template>
    <script>
import Betpop from "@/components/BetPop";
import BetPopsix from "@/components/BetPopsix";
import PayQrCode from "@/components/PayQrCode";
import {personalguessrecord, orderInfo, creat_qrcode,unlock_creat_qrcode,creat_qrcode_now} from "../../units/api.js";
import {getActivity} from "@/units/activity";
export default {
  name: "PersonalGuessrecord",
  data() {
    return {
      isAllChecked: false,
      imageUrl: '',
      selectedCheckboxes:[],
      selectedValues: [],
      BetListInputline: {},
      QrcodeData: {},
      BetListInputlineFlag: false,
      onOpenBetinfo: false,
      isprizegrade: false, //等级开关文案
      isopen: false,
      recorditems: [
        { name: "" },
        { name: "订单编号" },
        { name: "竞猜时间" },
        { name: "竞猜主题" },
        { name: "当前状态" },
        { name: "支付状态" },
        { name: "操作 " },
      ],
      recordlists: [],
      newrecordlists: [],
      orderId: null,
      editFlag: false,
      onOpenQrcode: this.onOpenQrcode,
      showModal: false
    };
  },
  props: {
    bets_lenght: {
       type: [Number, String], 
      default:""
    },
    modifydetail:{  
      type:Object,
      default(){  
         return { 
        }; 
      },}
  },
  created() {},
  mounted() {
    this.PersonalGuessrecord();   
  },
  components: {
    "Betpop-info": Betpop, 
    "Betpopsix-info": BetPopsix,
    "PayQrCode-info": PayQrCode,
  },
  methods: {
    openModal(imageUrlvalue) {
      if(imageUrlvalue!=''){
        this.showModal = true;
        this.imageUrl=imageUrlvalue;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    toggleSelectAll() {
      if (this.isAllChecked) {
        // this.selectedCheckboxes = this.newrecordlists.map(item => item.id);
        this.selectedCheckboxes = this.newrecordlists.map(item => {
          if (item.pay_status !=1 && item.pay_status !=2) {  //非已支付 和待审核的订单
            return  item.id
          }
        });
        console.log(this.selectedCheckboxes)
      } else {
        this.selectedCheckboxes = [];
      }
    },
    PersonalGuessrecord() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      let params = {activtiy_id:activtiyId, activity_round_id: activityRoundId };
      personalguessrecord(params).then((res) => {
        let response = res.data;
        that.recordlists = response.data;
        this.newrecordlists = this.recordlists.map((item) => {
          return {
            isOpen: false,
            ...item,
          };
        });
        // that.recordlists.forEach((item)=>{
        //   // console.log(item.activity_title,item.create_time,item.is_lottery)
        //   // console.log(item.info)
        //   that.recordlists.create_time = item.create_time
        //   console.log('jennyqqwewe',that.recordlists.create_time)
        //   that.recordlists = response.data;
        //   // console.log("jennytest",response.data)
        //   item.info.forEach((nums)=>{
        //       console.log(nums.num1,nums.lucky_num)
        //   })
        //   //console.log()
        // })
        console.log("记录获奖", that.recordlists);
      });
    },
    onOpenbet(id) {
         let nowTime = new Date(); 
         console.log("记录获奖",nowTime);
        let betting_end_time = new Date(
           this.modifydetail.betting_end_time
        );
        let betting_start_time = new Date(
          this.modifydetail.betting_start_time
        );  
        if (
          betting_end_time.getTime() < nowTime.getTime() || nowTime.getTime() < betting_start_time.getTime()
        ){  
          this.$toast.show("投注已截止咯～", 10000);  
           return; 
       } 
      if (!id) {
        this.BetListInputline = [];
        this.BetListInputlineFlag = true;
        return;
      }
      this.onOpenBetinfo = true;
      let that = this;
      orderInfo(id).then((res) => {
        let response = res.data;
        that.BetListInputline = response.data.betting_list;
        that.BetListInputlineFlag = true;
        that.orderId = id;
        that.editFlag = true;
        console.log("获取的信息", that.BetListInputline);
      });
    },
    IsClosebet() {
      this.BetListInputlineFlag = null;
    },
    IsCloseQrcode(){
      this.onOpenQrcode = false;
      this.PersonalGuessrecord();
    },
    inputBetnum(event) {
      this.Betnum = event.currentTarget.value;
    },
    DetailOpen: function (index) {
      this.$emit("jennyordertime")
      if (this.newrecordlists[index].isOpen === false) {
        this.newrecordlists[index].isOpen = true;
      } else {
        this.newrecordlists[index].isOpen = false;
      }
    },
    Payermaintenant: function (id){
      let activityInfo = getActivity()
          ? JSON.parse(getActivity())
          : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      creat_qrcode_now(
          { activtiy_id: activtiyId, activity_round_id: activityRoundId,order_id:id }
      ).then((res) => {
        if (res.status == 400) {
          this.$toast.show(res.msg, 10000);
          return;
        }else{
          this.QrcodeData=res.data
          this.onOpenQrcode = true
          console.log(this.QrcodeData)
        }
      });
    },
    payment:function(){
      this.selectedValues = [];
      for (let i = 0; i < this.selectedCheckboxes.length; i++) {
        if (this.selectedCheckboxes[i]) {
          this.selectedValues.push(this.selectedCheckboxes[i]);
        }
      }
      if(this.selectedValues.length==0){
        this.$toast.show('请选择订单', 10000);
      }else{
        let order_ids=this.selectedValues.join(',');
        let activityInfo = getActivity()
            ? JSON.parse(getActivity())
            : null;
        let activtiyId = activityInfo.activityId || null;
        let activityRoundId = activityInfo.activityRoundId || null;
        creat_qrcode(
            { activtiy_id: activtiyId, activity_round_id: activityRoundId,order_id:order_ids }
        ).then((res) => {
          if (res.status == 400) {
            this.$toast.show(res.msg, 10000);
            return;
          }else{
            this.QrcodeData=res.data
            this.onOpenQrcode = true
            console.log(this.QrcodeData)
          }
        });
      }
    },
    unlock:function(){
      this.selectedValues = [];
      for (let i = 0; i < this.selectedCheckboxes.length; i++) {
        if (this.selectedCheckboxes[i]) {
          this.selectedValues.push(this.selectedCheckboxes[i]);
        }
      }
      if(this.selectedValues.length==0){
        this.$toast.show('请选择订单', 10000);
      }else{
        let order_ids=this.selectedValues.join(',');
        let activityInfo = getActivity()
            ? JSON.parse(getActivity())
            : null;
        let activtiyId = activityInfo.activityId || null;
        let activityRoundId = activityInfo.activityRoundId || null;
        unlock_creat_qrcode(
            { activtiy_id: activtiyId, activity_round_id: activityRoundId,order_id:order_ids }
        ).then((res) => {
          if (res.status == 400) {
            this.$toast.show(res.msg, 10000);
            return;
          }else{
            let response = res.data;
            this.$toast.show(response.msg, 10000);
            this.PersonalGuessrecord();
          }
        });
      }
    },
    editDataChild: function (order_id, nums) {
      if(this.bets_lenght == 6){
        let item = this.newrecordlists.filter((v) => v.id == order_id);
      nums.forEach((num, index) => {
        if (num[0] != undefined) {
          if (item[0]["info"][index] == undefined) {
            item[0]["info"].push({
              num1: num[0],
              num2: num[1],
              num3: num[2],
              num4: num[3],
              num5: num[4],
              num6: num[5],
              lucky_num: num[6],
            });
          } else {
            item[0]["info"][index]["num1"] = num[0];
            item[0]["info"][index]["num2"] = num[1];
            item[0]["info"][index]["num3"] = num[2];
            item[0]["info"][index]["num4"] = num[3];
            item[0]["info"][index]["num5"] = num[4];
            item[0]["info"][index]["num6"] = num[5];
            item[0]["info"][index]["lucky_num"] = num[6];
          }
        }
      });
      }
      else{
        let item = this.newrecordlists.filter((v) => v.id == order_id);
      nums.forEach((num, index) => {
        if (num[0] != undefined) {
          if (item[0]["info"][index] == undefined) {
            item[0]["info"].push({
              num1: num[0],
              num2: num[1],
              num3: num[2],
              num4: num[3],
              num5: num[4],
              lucky_num: num[5],
            });
          } else {
            item[0]["info"][index]["num1"] = num[0];
            item[0]["info"][index]["num2"] = num[1];
            item[0]["info"][index]["num3"] = num[2];
            item[0]["info"][index]["num4"] = num[3];
            item[0]["info"][index]["num5"] = num[4];
            item[0]["info"][index]["lucky_num"] = num[5];
          }
        }
      });
      }
     
    },
  },
};
</script>
   <style lang="less"  >
   .modal {
     display: block;
     position: fixed;
     z-index: 9999;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     text-align: center;
     padding: 20px;
   }

   .modal img {
     max-width: 90%;
     max-height: 90%;
     margin: 0 auto;
   }
.pay_option{
  margin: 10px 20px 20px;
  display: flex;
}
.color_guess{
  margin-left: 15px;
  background: linear-gradient(to right, #dd0e19, #ff6555); /* 你可以根据需要更改渐变颜色 */
  color: white; /* 你可以根据需要更改字体颜色 */
  padding: 10px 15px; /* 你可以根据需要更改内边距 */
  font-size: 15px; /* 你可以根据需要更改字体大小 */
  cursor: pointer; /* 你可以根据需要更改鼠标指针样式 */
  border: none; /* 移除边框 */
  border-radius: 10px; /* 设置边框半径为 50px 以创建圆角矩形 */
}
.syj_PersonalGuessrecord {
  &::after {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background: #fff;
    content: "";
    left: 10px;
    top: 10px;
    z-index: -1;
  }
  z-index: 10;
  background: #fff;
  margin: 22px 0 70px;
  padding: 22px 20px 40px;
  color: #000;
  position: relative;
  .title {
    margin: 10px 20px 20px;
    display: flex;
    justify-content: space-around;
    font-weight: 600;
    & span {
      width: 20%;
    }
    & span:last-child {
      width: 40%;
    }
  }
  ul {
    font-size: 14px;
    li {
      & .recordlisttitle {
        width: 100%;
        display: flex;
        background: #fdf4ed;
        padding: 15px 0;
        border-bottom: 1px solid #fff;
      }
      & .betNum {
        width: 70%;
        justify-content: flex-start;
        padding: 20px 15%;
        flex-wrap: wrap;
        display: flex;
        & .syj_betnumDetail {
          & .prizegrade {
            padding-left: 5px;
            color: #000;
          }
          & .syj_betnumDetail-input {
            .inputnum {
              display: flex;
            }
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          display: flex;
          align-content: center;
          justify-content: flex-start;
          align-items: center;
          width: 50%;
        }

        display: flex;
      }
      & span {
        width: 25%;
      }
      & div.right {
        display: flex;
        justify-content: center;
        width: 50%;
        & span {
          &.gray{
    color: #9f9f9f;
    cursor: default;
}
          cursor: pointer;
        }
      }
      display: flex;
      justify-content: space-around;
      margin: 0 20px;
      flex-direction: COLUMN;
    }
  }

  .icon-middle.lefttop {
    left: -13px;
    top: -13px;
  }
  .icon-middle.leftbottom {
    left: -14px;
    bottom: -13px;
  }
  &.icon {
    &::before {
      right: -13px;
      bottom: -13px;
    }
    &::after {
      right: -13px;
      top: -13px;
    }
  }
  & .syj_betnumDetail {
    span {
      &:last-child {
        background: #3399ff;
        margin-left: 16px;
        position: relative;
        &:last-child::after {
          position: absolute;
          width: 7px;
          height: 1px;
          background: #3399ff;
          content: "";
          left: -14px;
          top: 50%;
        }
      }
      input {
        outline: none;
        text-align: center;
        width: 24px;
        height: 24px;
        padding: 0;
        background: transparent;
        border: none;
        color: #fff;
        font-size: 16px;
      }
      width: 24px;
      height: 24px;
      display: flex;
      margin: 2px 5px;
      border-radius: 0;
      background: #f04b38;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
    
    